import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './core/services/auth-guard.service';
import { DisallowtradeModule } from './modules/disallowtrade/disallowtrade.module';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'sessions/signin',
    pathMatch: 'full'
  },

  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./modules/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'DASHBOARD' }
      },

      {
        path: 'my-account',
        loadChildren: () => import('./modules/my-account/my-account.module').then(m => m.MyaccountModule),
        data: { title: 'My Account', breadcrumb: 'My Account' }
      },
      {
        path: 'setup/echo-margin',
        loadChildren: () => import('./modules/setup/update-echo-margin/update-echo-margin.module').then(m => m.UpdateEchomarginModule),
        data: { title: 'Setup', breadcrumb: 'Setup' }
      },
      {
        path: 'roles',
        loadChildren: () => import('./modules/administrator/roles/roles.module').then(m => m.AppRolesModule),
        data: { title: 'Roles', breadcrumb: 'Roles' }
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/administrator/users/users.module').then(m => m.AppUsersModule),
        data: { title: 'Users', breadcrumb: 'Users' }
      },
      {
        path: 'role-permission',
        loadChildren: () => import('./modules/administrator/role-permission/role-permission.module').then(m => m.AppRolePermissionModule),
        data: { title: 'Role Permission', breadcrumb: 'Role Permission' }
      },
      {
        path: 'company',
        loadChildren: () => import('./modules/company/web-setting.module').then(m => m.WebSettingModule),
        data: { title: 'Company Management', breadcrumb: 'Company Management' }
      },
      {
        path: 'stock',
        loadChildren: () => import('./modules/stock/stock.module').then(m => m.StockModule),
        data: { title: 'Stock Management', breadcrumb: 'Stock Management' }
      },
      {
        path: 'setup/plan-name',
        loadChildren: () => import('./modules/setup/subscription/plan-name/plan-name.module').then(m => m.AppPlannameModule),
        data: { title: 'Subscribe Plan Name', breadcrumb: 'Subscribe Plan Name' }
      },
      {
        path: 'setup/package-rule-engine',
        loadChildren: () => import('./modules/setup/subscription/package-rule-engine/package-rule-engine.module').then(m => m.AppPackageruleengineModule),
        data: { title: 'Subscribe Package Rule Engine', breadcrumb: 'Subscribe Package Rule Engine' }
      },
      {
        path: 'setup/subscribed-customer',
        loadChildren: () => import('./modules/setup/subscription/subscribed-customer/subscribed-customer.module').then(m => m.AppSubscribedCustomerModule),
        data: { title: 'Subscribed Customers', breadcrumb: 'Subscribed Customers' }
      },
      {
        path: 'setup/package-details',
        loadChildren: () => import('./modules/setup/subscription/package-details/package-details.module').then(m => m.AppPackageDatailsModule),
        data: { title: 'Subscribe Package Details', breadcrumb: 'Subscribe Package Details' }
      },
      {
        path: 'customer',
        loadChildren: () => import('./modules/customer/customer.module').then(m => m.AppCustomerModule),
        data: { title: 'Customer', breadcrumb: 'Customer' }
      },
      {
        path: 'missing-transaction-update',
        loadChildren: () => import('./modules/missing-transaction-update/missing-transaction-update.module').then(m => m.MissingTransactionUpdateModule),
        data: { title: 'Missing Transaction', breadcrumb: 'Missing Transaction ' }
      },
      {
        path: 'sub-trade',
        loadChildren: () => import('./modules/submitted-trade.ts/sub.module').then(m => m.AppSubmittedModule),
        data: { title: 'Submitted Trade Blotter', breadcrumb: 'Submitted Trade Blotter' }
      },
      {
        path: 'trade-blotter',
        loadChildren: () => import('./modules/trade-blotter/trade-blotter.module').then(m => m.AppTradeBlotterModule),
        data: { title: 'Trade Blotter', breadcrumb: 'Trade Blotter' }
      },
      {
        path: 'trade',
        loadChildren: () => import('./modules/trade/trade.module').then(m => m.AppTradeModule),
        data: { title: 'Trade', breadcrumb: 'Trade' }
      },
      {
        path: 'account',
        loadChildren: () => import('./modules/account/account.module').then(m => m.AppAccountModule),
        data: { title: 'Account', breadcrumb: 'Account' }
      },
      
      {
        path: 'disallowedtrade',
        loadChildren: () => import('./modules/disallowtrade/disallowtrade.module').then(m => m.DisallowtradeModule),
        data: { title: 'Disallowed Trade', breadcrumb: 'Disallowed Trade' }
      },
      {
        path: 'calendar',
        loadChildren: () => import('./modules/calendar/calendar.module').then(m => m.CalendarModule),
        data: { title: 'Calendar', breadcrumb: 'Calendar' }
      },
      {
        path: 'primary-investment',
        loadChildren: () => import('./modules/investment/primary-investment/primary-investment.module').then(m => m.AppPrimaryinvestmentModule),
        data: { title: 'Primary Investment', breadcrumb: 'Primary Investment' }
      },
      {
        path: 'secondary-investment',
        loadChildren: () => import('./modules/investment/secondary-investment/secondary-investment.module').then(m => m.AppSecondaryinvestmentModule),
        data: { title: 'Secondary Investment', breadcrumb: 'Secondary Investment' }
      },
      {
        path: 'feedback',
        loadChildren: () => import('./modules/feedback/feedback.module').then(m => m.AppFeedbackModule),
        data: { title: 'Feedback', breadcrumb: 'Feedback' }
      },
      {
        path: 'notification',
        loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
        data: { title: 'Notification', breadcrumb: 'Notification' }
      },
      {
        path: 'primary-investment-subcategory',
        loadChildren: () => import('./modules/investment/primary-investment-sub/primary-investment-sub.module').then(m => m.AppPrimaryinvestsubmentModule),
        data: { title: 'Primary Investment', breadcrumb: 'Primary Investment Subcategory' }
      },
      {
        path: 'secondary-investment-subcategory',
        loadChildren: () => import('./modules/investment/secondary-investment-sub/secondary-investment-sub.module').then(m => m.AppSecondaryinvestmentsubModule),
        data: { title: 'Secondary Investment ', breadcrumb: 'Secondary Investment Subcategory' }
      },
      {
        path: 'share',
        loadChildren: () => import('./modules/shares/shares.module').then(m => m.SharesModule),
        data: { title: 'Shares', breadcrumb: 'Shares' }
      },
      {
        path: 'channel',
        loadChildren: () => import('./modules/channel/channel.module').then(m => m.ChannelModule),
        data: { title: 'Channel', breadcrumb: 'Channel' }
      },
      {
        path: 'post',
        loadChildren: () => import('./modules/post/post.module').then(m => m.PostModule),
        data: { title: 'Posts', breadcrumb: 'Posts' }
      },
       {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
        data: { title: 'Reports', breadcrumb: 'Reports' }
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

