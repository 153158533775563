import { IMenuItem } from "./navigation.model";

export let adminMenuList: IMenuItem[] = [

  {
    name: "DASHBOARD",
    type: "link",
    tooltip: "Dashboard",
    icon: "dashboard",
    state: "dashboard/default",

  },
  {
    name: "Account",
    type: "link",
    tooltip: "Account",
    icon: "credit_card",
    state: "account/account-info",

  },
  {
    module: ['Company'],
    name: "Company",
    type: "link",
    tooltip: "Company",
    icon: "business",
    state: "company/list",
    sub: [
      {
        name: "Company", type: 'link', module: ['Company'], state: "company/view",
      }
    ]
  },
  {
    name: "Shares",
    type: "link",
    tooltip: "Shares",
    icon: "dynamic_feed",
    state: "share/list",
  },
  {
    module: ['customer'],
    name: "Customers",
    type: "dropDown",
    tooltip: "Customers",
    icon: "supervisor_account",
    state: "",
    sub: [
      {
        name: "Manage Customer", type: 'link', module: ['customer'], state: "customer/list",
        sub: [
          { name: "Manage Customer", type: 'link', module: ['customer'], state: "customer/view" }
        ]
      },
      { name: "Manage Profile", state: "lists" },
      { name: "Portfolio", state: "customer/lists" }
    ]
  },


  {
    module: ['missing transaction'],
    name: "Missing Transaction",
    type: "dropDown",
    tooltip: "Missing Transaction",
    icon: "dynamic_feed",
    

    sub: [
      {name: "Transaction Update", type: 'link', module: ['missing transaction'], state: "missing-transaction-update/list"},
      {name: "Send Notifications", type: 'link', module: ['missing transaction'], state: "missing-transaction-update/update"},
    ]
    

  },
  

  {
    name: "Leader Board",
    type: "link",
    tooltip: "Leader Board",
    icon: "chrome_reader_mode",
    state: "dashboard/default",

  },
  {
    name: "Disallowed Trade",
    type: "link",
    tooltip: "Disallowed Trade",
    icon: "chrome_reader_mode",
    state: "disallowedtrade",

  },
  {
    module: ['channel', 'post'],
    name: "Channel",
    type: "link",
    tooltip: "Channel",
    icon: "perm_media",
    state: "channel/list",

    // sub: [
    //   {name: "Manage Channel", type: 'link', module: ['channel'], ,
    //     sub: [
    //       { name: "Manage Channel", module: ['channel'], state: "channel/edit" },
    //       { name: "Manage Channel", module: ['channel'], state: "channel/view" },
    //       { name: "Manage Channel", module: ['channel'], state: "channel/create" },
    //     ]
    //   },
    //   {name: "Manage Post", type: 'link', module: ['post'], state: "post/list",
    //     sub: [
    //       { name: "Manage Post", module: ['post'], state: "post/edit" },
    //       { name: "Manage Post", module: ['post'], state: "post/view" },
    //       { name: "Manage Post", module: ['post'], state: "post/create" },
    //     ]
    //   },
    // ]
    

  },
  {
    module: ['report'],
    name: "Report",
    type: "dropDown",
    tooltip: "Reports",
    icon: "donut_small",
    

    sub: [
      {name: "Report", type: 'link', module: ['report'], state: "reports/post-report"},
      {name: "Deactivated Report", type: 'link', module: ['report'], state: "reports/deactivate-report"},
      {name: "Suspended Report", type: 'link', module: ['report'], state: "reports/suspend-report"},
      { name: "Appealed Report", type: 'link', module: ['report'], state: "reports/appealed-post-report"},
      { name: "Appealed Suspend Report", type: 'link', module: ['report'], state: "reports/appealed-suspend-report"}
    ]
    

  },
  {
    module: ['plan', 'package', 'rule_engine', 'subscribed_customers'],
    name: "Subscription",
    type: "dropDown",
    tooltip: "Subscription",
    icon: "subscriptions",
    state: "setup",
    sub: [
      {
        name: "Plan", module: ['plan'], state: "plan-name/list",
        sub: [
          { name: "Plan", module: ['plan'], state: "plan-name/edit" },
          { name: "Plan", module: ['Plan'], state: "plan-name/view" },
          { name: "Plan", module: ['Plan'], state: "plan-name/create" },
        ]
      },
      {
        name: "Rule Engine", module: ['rule_engine'], state: "package-rule-engine/list",
        sub: [
          { name: "Rule Engine", module: ['rule_engine'], state: "package-rule-engine/edit" },
          { name: "Rule Engine", module: ['rule_engine'], state: "package-rule-engine/view" },
          { name: "Rule Engine", module: ['rule_engine'], state: "package-rule-engine/create" },
        ]
      },
      {
        name: "Packages", module: ['package'], state: "package-details/list",
        sub: [
          { name: "Packages", module: ['package'], state: "package-details/edit" },
          { name: "Packages", module: ['package'], state: "package-details/view" },
          { name: "Packages", module: ['package'], state: "package-details/create" },
        ]
      },
      { name: "Subscribed Customers", module: ['subscribed_customers'], state: "subscribed-customer/view" }
    ]
  },
  {
    module: ['primary-investment', 'secondary-investment', 'primary-investment-subcategory', 'secondary-investment-subcategory'],
    name: "Investment Philosophy",
    type: "dropDown",
    tooltip: "Investment Philosophy",
    icon: "landscape",
    state: "",
    sub: [
      {
        name: "Primary Investment", module: ['primary-investment'], state: "primary-investment/list",
        sub: [
          { name: "Primary Investment", type: 'link', module: ['primary-investment'], state: "primary-investment/create" },
          { name: "Primary Investment", type: 'link', module: ['primary-investment'], state: "primary-investment/edit" },
        ]
      },
      {
        name: "Primary Subcategory", module: ['primary-investment-subcategory'], state: "primary-investment-subcategory/list",
        sub: [
          { name: "Primary Subcategory", type: 'link', module: ['primary-investment-subcategory'], state: "primary-investment-subcategory/create" },
          { name: "Primary Subcategory", type: 'link', module: ['primary-investment-subcategory'], state: "primary-investment-subcategory/edit" },
        ]
      },
      {
        name: "Secondary Investment", module: ['secondary-investment'], state: "secondary-investment/list",
        sub: [
          { name: "Secondary Investment", type: 'link', module: ['secondary-investment'], state: "secondary-investment/create" },
          { name: "Secondary Investment", type: 'link', module: ['secondary-investment'], state: "secondary-investment/edit" },
        ]
      },
      {
        name: "Secondary Subcategory", module: ['secondary-investment-subcategory'], state: "secondary-investment-subcategory/list",
        sub: [
          { name: "Secondary Subcategory", type: 'link', module: ['secondary-investment-subcategory'], state: "secondary-investment-subcategory/create" },
          { name: "Secondary Subcategory", type: 'link', module: ['secondary-investment-subcategory'], state: "secondary-investment-subcategory/edit" },
        ]
      },
    ]
  },
  {
    module: ['Stocks'],
    name: "Stocks",
    type: "link",
    tooltip: "Stocks",
    icon: "business_center",
    state: "stock/list",
    sub: [
      {
        name: "Stocks", type: 'link', module: ['Stocks'], state: "stock/view",
      }
    ]
  },
  {
    name: "Wallet",
    type: "link",
    tooltip: "Wallet",
    icon: "card_giftcard",
    state: "dashboard/default",

  },
  {
  name: "Trade Blotter",
  type: "link",
  tooltip: "Trade Blotter",
  icon: "add_shopping_cart",
  state: "trade-blotter/create",

},
{
  name: "Submitted Trade Blotter",
  type: "link",
  tooltip: "Submitted Trade Blotter",
  icon: "add_shopping_cart",
  state: "sub-trade",

},
  {
    name: "Trade",
    type: "dropDown",
    tooltip: "Trade",
    icon: "add_shopping_cart",
    state: "",
    sub: [
      { name: "Orders", state: "trade/orders/list" },
      { name: "Position", state: "trade/position/list" },
      { name: "Assets", state: "trade/asset/list" },
      { name: "Activities", state: "trade/active/list" },
    ]
  },
  {
    name: "Payment Transactions",
    type: "dropDown",
    tooltip: "Payment Transactions",
    icon: "credit_card",
    state: "",
    sub: [
      { name: "Customer's Deposit", state: "confirm" },
      { name: "Customer's Withdraw", state: "loader" },
      { name: "Echo Margin", state: "loader" },
    ]
  },
  {
    name: "News",
    type: "link",
    tooltip: "News",
    icon: "tv",
    state: "dashboard/default",

  },
  {
    name: "Advertisement",
    type: "link",
    tooltip: "Advertisement",
    icon: "new_releases",
    state: "dashboard/default",

  },
  {
    name: "Calendar",
    type: "link",
    tooltip: "Calendar",
    icon: "event",
    state: "calendar/list",

  },
  {
    name: "Feedback",
    type: "link",
    tooltip: "Feedback",
    icon: "feedback",
    state: "feedback",

  },
  {
    module: ['notification'],
    name: "Notification",
    type: "link",
    tooltip: "Notification",
    icon: "notifications",
    state: "notification",
    sub: [
      { name: "Notification Create", state: "notification/create" },
      { name: "Notification Update", state: "notification/update" },
    ]
  },
  {
    module: ['user', 'role', 'role_permission'],
    name: "Administrator",
    type: "dropDown",
    tooltip: "Administrator",
    icon: "settings",
    state: "",
    sub: [
      {
        name: "Manage Roles", module: ['role'], state: "roles/list",
        sub: [
          { name: "Manage Roles", module: ['role'], state: "roles/create" },
          { name: "Manage Roles", module: ['role'], state: "roles/edit" },
        ]
      },
      {
        name: "Manage Users", module: ['user'], state: "users/list",
        sub: [
          { name: "Manage Users", module: ['user'], state: "users/create" },
          { name: "Manage Users", module: ['user'], state: "users/edit" },
        ]
      },
      { name: "Manage Roles & Permission", module: ['role_permission'], state: "role-permission/list" },
    ]
  },
  {
    module: ['margin'],
    name: "Setup",
    type: "dropDown",
    tooltip: "Setup",
    icon: "update",
    state: "setup",
    sub: [
      { name: "Echo Margin", module: ['margin'], state: "/echo-margin/update" },
      { name: "Channels", state: "loader" },
      { name: "Chatters", state: "loader" },
    ]
  },
];